<template>
    <div class="articles-table">
        <vx-card>
            <vue-bootstrap4-table :rows="data" :columns="getColumns" :config="config" :actions="actions"
                                  @on-change-per-page="onChangePerPage"
                                  @on-change-query="onChangeQuery" :total-rows="total_rows" columnSelection>
                <template slot="active" slot-scope="props">
                  <div class="pl-2">
                    <vs-row>
                      <vx-tooltip text="Inaktiv">
                        <span class="mega--circle red" v-if="!props.row.active"></span>
                      </vx-tooltip>
                      <vx-tooltip text="Aktiv">
                        <span class="mega--circle green" v-if="props.row.active"></span>
                      </vx-tooltip>
                    </vs-row>
                  </div>
                </template>
                <template slot="row_actions" slot-scope="props">
                    <div class="column-actions-group">

                        <vx-tooltip text="Bearbeiten" position="left">
                            <router-link :to="'/products-and-services/'+props.row.id" >
                                <vs-button color="warning" type="filled" icon="edit" size="small"></vs-button>
                            </router-link>
                        </vx-tooltip>
                        <vx-tooltip text="Deaktivieren" position="left" v-if="props.row.active">
                            <vs-button color="danger"
                                       type="filled"
                                       icon="close"
                                       size="small"
                                       @click="toggleActive(props.row.id, props.row.active)"></vs-button>
                        </vx-tooltip>
                        <vx-tooltip text="Aktivieren" position="left" v-if="!props.row.active">
                            <vs-button color="success"
                                       type="filled"
                                       icon="check"
                                       size="small"
                                       @click="toggleActive(props.row.id, props.row.active)"></vs-button>
                        </vx-tooltip>
                        <vx-tooltip text="Löschen" position="left">
                            <vs-button color="primary"
                                       type="filled"
                                       icon="delete_forever"
                                       size="small"
                                       @click="openDeleteConfirm(props.row.id)"></vs-button>
                        </vx-tooltip>
                    </div>
                </template>
                <template slot="empty-results">
                    Keine Produkte gefunden.
                </template>

                <template slot="global_search-after">
                    <v-select
                            v-model="selectedCategories"
                            :options="categories"
                            label="name"
                            class="w-1/2 ml-3"
                            :searchable="false"
                            placeholder="Kategorien filtern"
                            @input="changeCategories"
                            multiple>
                    </v-select>
                </template>


            </vue-bootstrap4-table>
        </vx-card>
    </div>
</template>

<script>
    import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'

    import ApiService from "../../../api";

    import moment from 'moment';
    import QueryHelper from "../../../mixins/helper/query.helper";

    import findIndex from "lodash/findIndex";
    import PriceHelper from "../../../mixins/helper/price.helper";

    var qs = require('qs');

    import vSelect from 'vue-select'

    export default {
        components: {
            VueBootstrap4Table,
            'v-select': vSelect
        },
        data() {
            return {
                data: [],
                actions: [
                    {
                        btn_text: "Hinzufügen",
                        router_link: '/products-and-services/add',
                        color: 'success'
                    }
                ],
                columns: [
                    {
                        label: "Order-Nr.",
                        name: "ordernumber",
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        column_classes: 'ordernumner-column',
                        showCol: true,
                    },
                    {
                        label: "Name",
                        name: "name",
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        column_classes: 'name-column',
                        showCol: true,
                    },
                    {
                        label: "Beschreibung",
                        name: "description",
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        column_classes: 'desc-column',
                        showCol: false,
                    },
                    {
                        label: "Art",
                        name: "type.name",
                        slot_name: "amount",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        column_classes: 'type-column',
                        filter: {
                            type: "select",
                            placeholder: "Auswählen",
                            options: []
                        },
                        showCol: true,
                    },
                    {
                        label: "Preis",
                        name: "price",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        column_classes: 'price-column',
                        formatter: PriceHelper.formatCurrency,
                        showCol: true,
                    }, {
                        label: "Vertragskondition notwendig?",
                        name: "require_contract_condition",
                        sort: true,
                        filter: {
                            type: "simple",
                            placeholder: "1/0..."
                        },
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: false,
                    },
                    {
                        label: "Stundensatz-Artikel",
                        name: "is_hour_unit",
                        sort: true,
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        column_classes: 'price-column',
                        showCol: false,
                    },
                  {
                    label: "Lizenzprodukt",
                    name: "is_licence_product",
                    sort: true,
                    filter: {
                      type: "simple",
                      placeholder: "Suchen...1/0"
                    },
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'licence-column',
                    showCol: false,
                  },
                  {
                    label: "Lizenzprodukt (technischer Name)",
                    name: "licence_technical_name",
                    sort: true,
                    filter: {
                      type: "simple",
                      placeholder: "Suchen..."
                    },
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'licence_technical_name-column',
                    showCol: false,
                  },
                    {
                        label: "Erstellt",
                        name: "created_at",
                        slot_name: "created_at",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        column_classes: 'date-column',
                        showCol: false,
                        formatter: this.formatDateTime
                    },
                    {
                        label: "Aktiv",
                        name: "active",
                        slot_name: "active",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        column_classes: 'active-column',
                        showCol: true,
                      filter: {
                        type: "simple",
                        placeholder: "Suchen..."
                      },
                    },
                    {
                        label: "Aktionen",
                        slot_name: "row_actions",
                        sort: false,
                        row_text_alignment: 'text-right',
                        column_text_alignment: 'text-right',
                        column_classes: 'actions-column',
                        showCol: true,
                    }],
                config: {
                    checkbox_rows: false,
                    show_refresh_button: false,
                    show_reset_button: false,
                    highlight_row_hover_color: '#f8f8f8',
                    server_mode: true,
                    per_page: 10
                },
                queryParams: {
                    sort: [],
                    filters: [],
                    global_search: "",
                    per_page: 10,
                    page: 1,
                },
                total_rows: 0,
                selectedForDialog: null,
                categories: [],
                selectedCategories: []
            }
        },
        computed: {
            requestParams() {
                let queryParams =  QueryHelper.parseRequestParams(this.queryParams);

                if (this.selectedCategories !== null && this.selectedCategories.length > 0) {
                    const categoryFilter = [];

                    this.selectedCategories.forEach((category) => {
                        categoryFilter.push({
                            field: 'categories.id',
                            value: category.id,
                            expression: 'exact'
                        })
                    });

                    queryParams.filter.push({
                        expression: 'group',
                        filter: categoryFilter
                    });
                }

                return queryParams;
            },
            getColumns() {
                return this.columns;
            },
            categoriesPromise() {
                const filter = '?filter[0][field]=usable_on' +
                    '&filter[0][value]=' + 'product';

                return ApiService.get('categories' + filter);
            }
        },
        methods: {
            fetchData() {
                this.$vs.loading();

                let articleTypesPromise = ApiService.get('article_types');

                let articlesPromise = ApiService.get('articles', {
                    params: this.requestParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                });

                Promise.all([articleTypesPromise, articlesPromise, this.categoriesPromise])
                    .then(([types, articles, categories]) => {
                        let options = types.data.result;

                        options = options.map(function(option) {
                            return {id: option.name, label: option.name};
                        });

                        let index = findIndex(this.columns, {name: 'type.name'});

                        if(index !== -1) {
                            this.columns[index].filter.options = options;
                        }

                        this.data = articles.data.result;

                        this.total_rows = articles.data.total;


                        this.categories = categories.data.result.map(function(option) {
                            return {id: option.id, label: option.name, name: option.name};
                        }, []);

                        this.$vs.loading.close();
                    });
            },
            refreshProducts() {
                ApiService.get('articles', {
                    params: this.requestParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                }).then((response) => {
                    this.data = response.data.result;
                    this.total_rows = response.data.total;
                });
            },
            changeCategories() {
                this.refreshProducts();
            },
            formatDateTime(value) {
                return moment(new Date(value)).format('DD.MM.YYYY - H:m')
            },
            onChangeQuery(queryParams) {
                this.queryParams = queryParams;
                this.fetchData();
            },
            onChangePerPage(value) {
                this.config.per_page = value;
            },
            toggleActive(id, current) {

                const payload = {
                    active: !parseInt(current)
                };

                ApiService.put('articles/' + id, payload).then(response => {
                    this.fetchData();

                    if(response.data.status === 'success') {
                        return this.$vs.notify({
                            title: 'Erfolgreich',
                            text: 'Der Status wurde erfolgreich geändert',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        })
                    }

                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Der Status konnte nicht geändert werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })

                }).catch((error) => {
                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Der Status konnte nicht geändert werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })
            },
            openDeleteConfirm(id) {
                this.selectedForDialog = id;

                this.$vs.dialog({
                    type: 'confirm',
                    color: 'primary',
                    title: 'Bestätigen',
                    text: 'Sind Sie sich sicher dass dieser Artikel gelöscht werden soll?',
                    accept: this.acceptDelete,
                    acceptText: 'Löschen',
                    cancelText: 'Abbrechen'
                })
            },
            acceptDelete() {
                ApiService.delete('articles/' + this.selectedForDialog).then((response) => {

                    this.fetchData();

                    if(response.data.status === 'success') {
                        return this.$vs.notify({
                            title: 'Erfolgreich',
                            text: 'Der Artikel wurde erfolgreich gelöscht',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        });
                    }

                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Der Artikel konnte nicht gelöscht werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                }).catch((error) => {
                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Der Artikel konnte nicht gelöscht werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })
            },
        },
    }

</script>

<style lang="scss">
    .articles-table {
        .ordernumner-column {
            width: 10%;
        }
        .type-column {
            width: 15%;
        }
        /*
        .desc-column {
            width: 20%;
        }
        .name-column {
            width: 20%;
        }
        .price-column {
            width: 7.5%;
        }
        .date-column {
            width: 15%;
        }
        */
        .actions-column {
            width: 8%;
        }
        .active-column {
            width: 6%;
        }
    }
</style>
